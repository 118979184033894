
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.bg {
    background: #8ebadf url(/assets/images/street.png) bottom repeat-x;
    background-size: 165px;
}
.mainBannerBg {
    position: relative;
    height: calc(100vh - 58px);
    min-height: 700px;
    box-sizing: border-box;
    padding-top: 5vh;
}
.mainBannerCar {
    position: absolute;
    right: 0;
    bottom: -6px;
}
.roundedBox {
    background-color: #fff;
    padding: 25px 23px;
    box-shadow: 0px 0px 60px 40px rgb(0 0 0 / 15%);
    @apply mx-5;
    @include sm {
        @apply mx-auto;
    }
    position: relative;
    z-index: 1;
}
.highlight {
    color: #ff8500;
}
.mainTitle {
    font-size: 22px;
    color: #1b447f;
    margin: 0 0 15px;
    @media screen and (min-width: 500px) {
        font-size: 35px;
    }
}

.mainDesc {
    color: #4e6e9c;
    font-size: 16px;
    line-height: 1.4;
    margin: 15px auto;
    max-width: 500px;
}

.mainInputWrapper {
    padding: 16px 10px;

    width: 100%;
    max-width: 611px;
    background-color: #f8f8f8;
    @apply rounded-xl;
    @include md {
        padding: 16px 50px;
    }
}
.zipCodeField {
    width: 100%;
    height: 40px;
    padding: 15px 18px;
    border: solid 1px #cecece;
    border-radius: 7px;
    margin-bottom: 15px;
    font-size: 18px;
    &::placeholder {
        color: #b7b7b7;
    }
    @include md {
        height: 45px;
    }
    &:focus {
        outline: none;
    }
}

.whiteTxt {
    color: #fff;
}
.imgBlock {
    @media screen and (max-width: 767px) {
        width: 18%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

.smallTxt {
    color: #666666;
    font-size: 12px;
    @media screen and (max-width: 767px) {
        font-size: 11px;
    }
}

.error {
    color: #d10202;
    @apply text-sm font-light text-center block pt-2 pb-3;
}

.select-question {
    display: flex;
    background-color: white;
    align-items: center;
    margin-right: 0px;
    position: relative;
    background-image: linear-gradient(45deg, transparent 50%, black 50%),
        linear-gradient(135deg, black 50%, transparent 50%);
    background-position: calc(100% - 12px) 18px, calc(100% - 7px) 18px,
        calc(100% - 2.5em) 3px;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    padding: 10px 10px 10px 10px;

    label {
        color: $insurance-super-saver-assault;
        @apply text-xs lg:text-sm font-light mr-2;
    }
    .value {
        padding-right: 9px;
        color: $insurance-super-saver-assault;
        @apply text-xs lg:text-sm;
    }
    select {
        border: none;
        outline: none;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        &:focus {
            outline: none;
            border: none;
        }
    }
    @include lg {
        padding: 10px 20px 10px 15px;
        background-position: calc(100% - 12px) 20px, calc(100% - 7px) 20px,
            calc(100% - 2.5em) 3px;
    }
}

.toggle-question {
    margin-right: 20px;
    margin-bottom: 10px;
    input {
        position: absolute;
        opacity: 0;

        &:checked + label .checkbox svg path {
            stroke-dashoffset: 0;
        }
        &:checked + label .checkbox {
            background-color: $insurance-super-saver-blue-aster;
        }

        & + label {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            cursor: pointer;
            color: $insurance-super-saver-assault;
            @apply text-xs lg:text-sm;
            .checkbox {
                display: block;
                border: 2px solid $insurance-super-saver-blue-aster;
                width: 18px;
                height: 18px;
                border-radius: 2px;
                cursor: pointer;
                transition: all 0.2s ease;
                margin-right: 5px;
                padding: 2px;

                svg {
                    pointer-events: none;
                    path {
                        fill: none;
                        stroke: white;
                        stroke-width: 6px;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 100;
                        stroke-dashoffset: 101;
                        transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
                    }
                }
                @include lg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
.listing-filters {
    width: 100%;
    border-radius: 2px;

    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        &.boxes {
            justify-content: space-between;
            .question {
                height: 40px;

                border: 1px solid #cecece;
                margin-right: 0px;
                width: 48.5%;
                margin-bottom: 10px;
                border-radius: 7px;
                @include md {
                    height: 45px;
                }
            }
        }
    }
}
