
            @import 'src/styles/mixins.scss';
        
.offer{
    h3{
        color: #266199;
    }
    h2{
        color: #5675a0;
    }
}
.button {
    text-align: right;
    overflow-x: hidden;
    a {
        background-color: hsl(32, 93%, 56%);
        color: white;
        display: block;
        width: 100%;
        border-radius: 5px;
        text-align: center;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;
        float: right;
        padding: 15px 30px;
        @apply text-sm;
        margin: 2rem 0;
        &:hover {
            background-color: hsl(32, 73%, 56%);
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
        }
    }
}