
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.submit-button {
    width: 100%;
    background: #ff8c0d;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    &::before {
        content: "";
        background-color: rgba(255, 255, 255, 0.2);
        height: 100%;
        width: 3em;
        display: block;
        position: absolute;
        top: 0;
        left: -4.5em;
        transform: skewX(-45deg) translateX(-130%);
        transition: none;
        animation: blink 2.4s ease infinite;
    }
    @media screen and (max-width: 767px) {
        height: 54px;
        position: relative;
        width: 100%;
        right: 0;
    }
}

.form {
    width: 100%;
    max-width: 611px;
    background-color: #f8f8f8;
    border-radius: 0.75rem;
    padding: 20px 10px;
    position: relative;
    padding-bottom: 35px;
    margin-bottom: 20px;
    @include lg {
        padding: 20px 50px;
    }
}

.error {
    color: #d10202;
    white-space: nowrap;
    text-align: center;
    display: block;
    padding-top: 15px;
    @apply text-xs font-light;
}

.filters {
    border: none;
    padding: 0;
    width: calc(100% + 20px);
    margin: 0 -10px !important;
    .text-question {
        &:first-of-type {
            width: 100%;
            padding: 1px;
            border: 1px solid #cecece !important;
            height: 100%;
            margin: 0 10px;
            margin-bottom: 15px;
            background-color: white;

            &:focus-within {
                border-color: $insurance-super-saver-blue-aster !important;
            }
            input {
                padding: 15px 18px;
            }
        }
    }
    .select-question {
        margin: 0 10px !important;
        border-radius: 4px !important;
        background-color: white;
        border: 1px solid #cecece !important;
        width: calc(50% - 20px) !important;
        margin-bottom: 15px !important;
        @include lg {
        }
    }
    .toggle-question {
        border: none !important;
        margin: 0 5px !important;
        margin-left: 10px !important;
        width: calc(50% - 15px) !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        label {
            font-size: 0.75rem;
        }
    }
}

@keyframes blink {
    0% {
        transform: skewX(-45deg) translateX(-130%);
    }

    100% {
        transform: skewX(-45deg) translateX(40rem);
    }
}
